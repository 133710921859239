<template>
  <div class="demo2">
    <div class="demo_left">
      <div id="playVideo"></div>
    </div>
    <div class="demo_right">
      <div class="righttop">
        <el-input
          type="textarea"
          class="ipt"
          v-model="text"
          placeholder="输入问题"
        ></el-input>
        <el-button style="margin-left: 30px" type="primary" @click="huida"
          >回答问题</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { queAnswers } from "@/api";
export default {
  data() {
    return {
      answer: "",
      text: "",
      psyClient: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const userId = "6499612d07c8f4084d6a5d09";
      const ak = "6463324857e4cadc201fc791";
      const sk = "f0c03b2334c74a0b9d85a402941d9200";
      const playerElementId = "playVideo";
      this.psyClient = new PsyClient();
      this.psyClient.init(userId, ak, sk, playerElementId);
      setTimeout(() => {
        this.psyClient
          .startSession()
          .then((data) => {
            console.log(`startSession success data: ${data}`);
          })
          .catch((err) => {
            console.log("startSession failed", err);
          });
      }, 1000);
    },
    huida() {
      queAnswers({
        text: this.text,
      }).then((res) => {
        this.answer = res.value;
        this.psyClient.playMedia(this.answer);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demo2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  min-width: 1200px;
  .demo_left {
    width: 500px;
    #playVideo {
      width: 500px;
      height: 500px;
      /deep/.remote {
        position: static !important;
      }
    }
  }
  .demo_right {
    flex: 1;
    overflow: auto;
    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .ipt {
        height: 100%;
      }
      .el-button {
        height: 100%;
      }
    }
  }
}
</style>